.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(4px + 1vmin);
  color: white;
}
.contest-win-details {
  text-align: left;
}
.time {
  color: gray;  
}
.avatar {
  margin-right: 10px;
  height: 50px;
}
.contest-win {
  display: flex;
  align-items: center;
  justify-content:flex-start;
  margin-bottom: 10px
}

.App-logo {
  height: 40vmin;
}


.App-link {
  color: #09d3ac;
}
